import React from "react";
import Footer from "../../Components/Footer";
import './term.css'

const Sla = () => {
    return (
<div className="viewPort hmPageContainer">
<div class="card m-2">
  <h1 class="card-header">Service Level Agreement (SLA) for OnePaise</h1>
  <div class="card-body">
    <div class="calibre" id="calibre_link-0"></div>
  <p>This SLA outlines the levels of service and support that OnePaise will provide for the website <a href="https://onepaise.com">https://onepaise.com</a>.</p>
  <h2>Availability</h2>
  <p>The <a href="https://onepaise.com">https://onepaise.com</a> website will have an uptime of 99.5% per calendar month. In the event that the website is not available for a period exceeding 0.5% of the calendar month, OnePaise will provide a credit to the customer equal to 10% of the monthly service fee for each hour of downtime, up to a maximum of 100% of the monthly service fee.</p>
  <h2>Support</h2>
  <p>OnePaise will provide customer support for the <a href="https://onepaise.com">https://onepaise.com</a> website 24 hours a day, 7 days a week. Support will be provided via email and phone. Our support team will respond to customer inquiries within 4 hours during business hours and within 8 hours outside of business hours.</p>
  <h2>Maintenance</h2>
  <p>OnePaise will perform regular maintenance on the <a href="https://onepaise.com">https://onepaise.com</a> website to ensure optimal performance. Any scheduled maintenance will be performed during non-peak hours, and customers will be notified at least 24 hours in advance.</p>
  <h2>Security</h2>
  <p>OnePaise will take appropriate measures to protect the <a href="https://onepaise.com">https://onepaise.com</a> website from unauthorized access and data breaches. The website will comply with all relevant security standards and regulations, including but not limited to SOC 2, and ISO 27001.</p>
  <h2>Data Backup</h2>
  <p>OnePaise will perform regular backups of the <a href="https://onepaise.com">https://onepaise.com</a> website to ensure that customer data is protected in the event of an unexpected outage. Backups will be performed daily and kept for a minimum of 30 days.</p>
  <h2>Content Updates</h2>
  <p>OnePaise will provide customers with the ability to update the content of their website on an as-needed basis. Customers will be able to request content updates through our support team, and updates will be made within 48 hours of the request.</p>
  <h2>Changes to the SLA</h2>
  <p>OnePaise reserves the right to change the terms of this SLA at any time. Customers will be notified of any changes via email at least 30 days in advance.</p>
  <h2>Dispute Resolution</h2>
  <p>Any disputes arising under or in connection with this SLA will be resolved through arbitration under the rules of Indian arbitration association.</p>
  <h2>Governing Law</h2>
  <p>This SLA shall be governed by and construed in accordance with the laws of India.</p>
  <h2>Entire Agreement</h2>
  <p>This SLA constitutes the entire agreement between the customer and OnePaise with respect to the use of the https://onepaise.com website and supersedes all prior or contemporaneous communications and proposals, whether oral or written, between the customer and OnePaise.</p>
  </div>
  </div>
      <Footer />
    </div>
  );
};

export default Sla;

