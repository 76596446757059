import React, { useState, useEffect, useContext } from "react";
import { Link, useHistory } from "react-router-dom";
import BannerCarousel from "../../Components/BannerCarousel";
import SecondaryButton from "../../Components/Buttons/SecondaryButton";
import PrimaryButton from "../../Components/Buttons/PrimaryButton";
import { loginimages } from "../../Utils/Constants";
import ArrowBackIcon from "@material-ui/icons/ArrowBack";
import ArrowForwardIcon from "@material-ui/icons/ArrowForward";
import TextInput from "../../Components/TextField";
import { CommonContext } from "../../context/CommonContext";

import { setCookie } from "../../Utils/cookies";
import { useQuery, useLazyQuery } from "@apollo/client";
import { login } from "../../schema/user";

import { useFormik } from "formik";
import * as Yup from "yup";

const Login = (props) => {
  const { setAlert, setUser } = useContext(CommonContext);

  const initialValues = {
    mobile: "",
    password: "",
    fcm: "",
    deviceid: "",
    isUser: true,
  };

  const [userData, setUserData] = useState(initialValues);
  const history = useHistory();

  const [loginUser, { called, error, data }] = useLazyQuery(login, {
    variables: userData,
    skip: true,
  });

  const userValidationSchema = Yup.object({
    mobile: Yup.string().required("Mobile is required"),
    password: Yup.string().required("Password is required"),
  });

  const userFromik = useFormik({
    initialValues: initialValues,
    validationSchema: userValidationSchema,
    onSubmit: (values) => {
      setUserData({ ...values, isUser: true });
      loginUser();
    },
  });

  const operatorValidationSchema = Yup.object({
    mobile: Yup.string().required("Mobile is required"),
    password: Yup.string().required("Password is required"),
  });

  const operatorFromik = useFormik({
    initialValues: initialValues,
    validationSchema: operatorValidationSchema,
    onSubmit: (values) => {
      setUserData({ ...values, isUser: false });
      loginUser();
    },
  });

  useEffect(() => {
    if (error) setAlert({ msg: "Invalid login credentials, please check your mobile number or password", success: 0 });
    if (!error && data) {
      setAlert({ msg: "Successfully logged in!", success: 1 });
      setUser(data.login);
      if (data.login.isUser) {
        history.push("/userdashboard");
      } else {
        history.push("/operatordashboard");
      }
      setCookie("__user2", JSON.stringify(data.login), 1)
    }
  }, [data, error]);

  return (
    <div className="viewPort logContainer row">
      <div className="col-md-6 col-12">
        <BannerCarousel images={loginimages} />
      </div>
      <div className="col-md-6 logTextWrapper col-12 p-4 pr-5">
        <div className="backButton">
          <SecondaryButton
            onClick={() => history.push("/home")}
            label={<ArrowBackIcon />}
          />
        </div>
        <div className="contWrapper">
          <h1>
            <img src="./images/AIrPayLogo.svg" />
          </h1>
          {props.location.pathname === "/userlogin" ? (
            <form className="mt-5">
              <TextInput
                label="Mobile No. / கைபேசி எண்"
                name="mobile"
                values={userFromik.values.mobile}
                onChange={userFromik.handleChange}
                error={userFromik.errors}
              />
              <div className="d-flex w-100 align-items-center">
                <TextInput
                  label="Password / கடவுச்சொல்"
                  name="password"
                  values={userFromik.values.password}
                  onChange={userFromik.handleChange}
                  error={userFromik.errors}
                  type="password"
                />
                <PrimaryButton
                  label={<ArrowForwardIcon />}
                  onClick={userFromik.handleSubmit}
                />
              </div>
              <p className="info">
                புதிய கணக்கிற்கு உங்கள் கேபிள் டிவி ஆபரேட்டரைத் தொடர்பு
                கொள்ளுங்கள்
              </p>
            </form>
          ) : (
            <form className="mt-5">
              <TextInput
                label="Mobile No. / கைபேசி எண்"
                name="mobile"
                values={operatorFromik.values.mobile}
                onChange={operatorFromik.handleChange}
                error={userFromik.errors}
              />
              <div className="d-flex w-100 align-items-center mt-3">
                <TextInput
                  label="Password / கடவுச்சொல்"
                  name="password"
                  values={operatorFromik.values.password}
                  onChange={operatorFromik.handleChange}
                  error={userFromik.errors}
                />
                <PrimaryButton
                  onClick={operatorFromik.handleSubmit}
                  label={<ArrowForwardIcon />}
                />
              </div>
              <p className="info">
                Need an account?&nbsp;
                <Link rel="preload" to="/contactus">
                  Contact Us
                </Link>
                <br />
                புதிய கணக்கு தேவையா?&nbsp;
                <Link rel="preload" to="/contactus">
                  இங்கே கிளிக் செய்க
                </Link>
              </p>
            </form>
          )}
        </div>
      </div>
    </div>
  );
};

export default Login;
