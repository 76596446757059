import React, { useState, useEffect, useContext } from "react";
import { Link, useHistory } from "react-router-dom";
import TextInput from "../../Components/TextField";
import { useQuery, useLazyQuery, useMutation } from "@apollo/client";
import { CommonContext } from "../../context/CommonContext";
import { userById, userUpdateOne, channelPagination } from "../../schema/user";
import { Formik, useFormik } from "formik";
import * as Yup from "yup";
import PrimaryButton from "../../Components/Buttons/PrimaryButton";
import SecondaryButton from "../../Components/Buttons/SecondaryButton";
import { IconButton } from "@material-ui/core";
import Select from "react-select";
import { object } from "yup";

import Zazorpay from "../../Components/Payment/zaropay-single";

const EditUser = (props) => {
  const initialValues = {
    fullName: "",
    countryCode: "",
    phone: "",
    zipCode: "",
    deviceId: "",
    fbId: "",
    twiterId: "",
    emailId: "",
    currency: { value: "INR", label: "INR", selected: true },
    location: "",
    address: "",
    brand_name: "",
    logo: "",
    role: "user",
    street_address: "",
    apartment_number: "",
    account: "",
    extra_chennel: [],
    channels: [],
    extra_chennel_total: 0,
    extra_chennel_temp_price: 0
  };

  const { setAlert, user } = useContext(CommonContext);
  const [touched, setTouched] = useState(false);
  const [userData, setUserData] = useState(initialValues);
  const history = useHistory();

  const [variables, setVariables] = React.useState({
    id: user._id,
  });
  const [getUserInfo, { called, error, data }] = useLazyQuery(userById, {
    variables: variables,
  });

  const [updateUserInfo, { called: called2, error: error2, data: data2 }] =
    useMutation(userUpdateOne, {
      variables: { record: {}, filter: {} },
    });

  const [getChannels, { called: called3, error: error3, data: data3 }] =
    useLazyQuery(channelPagination, {
      variables: { filter: { operator_id: user.operator_id } },
    });

  const userValidationSchema = Yup.object({
    fullName: Yup.string().required("required"),
    phone: Yup.string().required("required"),
    emailId: Yup.string().required("required"),
    apartment_number: Yup.string().required("required"),
    street_address: Yup.string().required("required"),
    address: Yup.string().required("required"),
    zipCode: Yup.string().required("required"),
  });

  const userFromik = useFormik({
    initialValues: initialValues,
    validationSchema: userValidationSchema,
    onSubmit: (values) => {
      let totalPrice = 0;
      values.extra_chennel = values.extra_chennel.map((data) => {
        totalPrice += parseFloat(data.value);
        return { chennel: data.chennel, value: data.value }
      });
      let payload = {
        record: {
          fullname: values.fullName,
          country_code: values.countryCode,
          phone: values.phone,
          zipcode: values.zipCode,
          device_id: values.deviceId,
          fb_id: values.fbId,
          twitter_id: values.twiterId,
          email: values.emailId,
          address:
            values.apartment_number +
            "," +
            values.street_address +
            "," +
            values.address,
          extra_chennel: values.extra_chennel,
          extra_chennel_total: totalPrice.toString(),
        },
        filter: {
          _id: user._id,
        },
      };
      updateUserInfo({ variables: payload });
    },
  });

  useEffect(() => {
    if (user._id) {
      getUserInfo(variables);
      getChannels();
    }
  }, [user]);

  useEffect(() => {
    if (error) {
      setAlert({ msg: "Try later!", success: 0 });
    }
    if (!error && data) {
      console.log(data)
      var obj = {
        fullName: data.userById.fullname,
        countryCode: data.userById.country_code,
        phone: data.userById.phone,
        zipCode: data.userById.zipcode,
        deviceId: data.userById.device_id,
        fbId: data.userById.fb_id,
        twiterId: data.userById.twitter_id,
        emailId: data.userById.email,
        Currency: { value: "INR", label: "INR", selected: true },
        address: data.userById.address.split(",")[2],
        street_address: data.userById.address.split(",")[1],
        apartment_number: data.userById.address.split(",")[0],
        brand_name: data.userById.brand_name,
        logo: data.userById.logo,
        account: data.userById.account,
        extra_chennel: data.userById.extra_chennel || [],
        extra_chennel_total: data.userById.extra_chennel_total || 0
      };
      Object.keys(obj).map((data) => {
        console.log(data, obj[data])
        userFromik.setFieldValue(data, obj[data]);
      });
    }
  }, [data, error]);

  useEffect(() => {
    if (error3) {
      setAlert({ msg: "Try later!", success: 0 });
    }
    if (!error3 && data3) {
      let items = [];
      data3.channelPagination.items.map((item) => {
        items.push({
          value: item.price,
          label: item.name,
        });
        return items;
      });
      userFromik.setFieldValue("channels", items);
    }
  }, [data3, error3]);

  useEffect(() => {
    if (error2) {
      setAlert({ msg: "Try later!", success: 0 });
    }
    if (!error2 && data2) {
      setAlert({ msg: "Updated Successfully!", success: 1 });
    }
  }, [data2, error2]);

  const updatePrice = (price) => {
    userFromik.setFieldValue("extra_chennel_temp_price", userFromik.values.extra_chennel_temp_price + Number(price));
  }
  const addChennels = () => {
    let temp = userFromik.values.extra_chennel.length ? userFromik.values.extra_chennel : []
    temp = temp.map((data) => data)
    temp.push({ chennel: "", value: "" })
    userFromik.setFieldValue("extra_chennel", temp);
  };
  const removeChennels = (index) => {
    let temp = [...userFromik.values.extra_chennel]
    userFromik.setFieldValue("extra_chennel_temp_price",
      userFromik.values.extra_chennel_temp_price - Number(userFromik.values.extra_chennel[index].value));
    temp.splice(index, 1);
    userFromik.setFieldValue("extra_chennel", temp);
  };

  useEffect(() => {
    Object.keys(userFromik.touched).length && setTouched(true)
  }, [userFromik.touched])

  return (
    <>
      <div className="content">
        <div className="row my-5" style={{ maxWidth: "800px", margin: "auto" }}>
          <div className="col-md-6 col-12">
            <TextInput
              label="Name / பெயர்"
              name="fullName"
              value={userFromik.values.fullName}
              onChange={userFromik.handleChange}
              error={userFromik.errors && touched ? userFromik.errors : ''}
            />
          </div>
          {/* <div className="col-md-6 col-12">
            <TextInput
              label="Country Code / நாட்டின் தொலைபேசி குறியீடு"
              name="countryCode"
              value={userFromik.values.countryCode}
              onChange={userFromik.handleChange}
              error={userFromik.errors && touched ? userFromik.errors : ''}
            />
          </div> */}
          <div className="col-md-6 col-12">
            <TextInput
              label="Phone / கைபேசி எண்"
              name="phone"
              value={userFromik.values.phone}
              onChange={userFromik.handleChange}
              error={userFromik.errors && touched ? userFromik.errors : ''}
            />
          </div>
          <div className="col-md-6 col-12">
            <TextInput
              label="Email / மின்னஞ்சல்"
              name="emailId"
              value={userFromik.values.emailId}
              onChange={userFromik.handleChange}
              error={userFromik.errors && touched ? userFromik.errors : ''}
            />
          </div>
          <div className="col-md-6 col-12">
            <TextInput
              label="Apartment Number / அபார்ட்மெண்ட் எண்"
              name="apartment_number"
              value={userFromik.values.apartment_number}
              onChange={userFromik.handleChange}
              error={userFromik.errors && touched ? userFromik.errors : ''}
            />
          </div>
          <div className="col-md-6 col-12">
            <TextInput
              label="Address / முகவரி"
              name="street_address"
              value={userFromik.values.street_address}
              onChange={userFromik.handleChange}
              error={userFromik.errors && touched ? userFromik.errors : ''}
            />
          </div>
          <div className="col-md-6 col-12">
            <TextInput
              label="State / மாநிலம்"
              name="address"
              value={userFromik.values.address}
              onChange={userFromik.handleChange}
              error={userFromik.errors && touched ? userFromik.errors : ''}
            />
          </div>
          <div className="col-md-6 col-12">
            <TextInput
              label="Zip Code / அஞ்சல் குறியீடு"
              name="zipCode"
              value={userFromik.values.zipCode}
              onChange={userFromik.handleChange}
              error={userFromik.errors && touched ? userFromik.errors : ''}
            />
          </div>
          <div className="col-md-12">
            <hr />
            <div className="d-flex align-items-center justify-content-between">
              <p className="mb-0">
                Add Extra Channels / சேனல்களைச் சேர்க்கவும்
              </p>
              <SecondaryButton
                label={
                  <>
                    <span className="material-icons">add</span> add / சேர்
                  </>
                }
                onClick={addChennels}
              />
            </div>
            <div className="row my-3">
              {userFromik.values.extra_chennel.map((data, index) => (
                <>
                  <div
                    className="col-md-5 col-12 cstxtFldWrp mt-1"
                    key={`rec_${index}`}
                  >
                    <Select
                      className="react-select info"
                      classNamePrefix="react-select"
                      name={`extra_chennel_${index}`}
                      value={userFromik.values.channels.find(
                        (data) =>
                          data.label ===
                          userFromik.values.extra_chennel[index].chennel
                      )}
                      onChange={(e) => {
                        let temp = userFromik.values.extra_chennel;
                        temp[index].chennel = e.label;
                        temp[index].value = e.value;
                        updatePrice(e.value);
                        userFromik.setFieldValue("extra_chennel", temp);
                      }}
                      options={userFromik.values.channels}
                      placeholder="Select Chennel / சேனலைத் தேர்ந்தெடுக்கவும்"
                    />
                  </div>
                  <div
                    className="col-md-5 col-12 cstxtFldWrp"
                    key={`recVal_${index}`}
                  >
                    <input
                      className="cstmInputValue"
                      name={`extra_chennel_value_${index}`}
                      value={userFromik.values.extra_chennel[index].value}
                      onChange={(e) => {
                        let temp = userFromik.values.extra_chennel;
                        temp[index].value = e.target.value;
                        userFromik.setFieldValue("extra_chennel", temp);
                      }}
                      placeholder="Channel Price / சேனல் விலை"
                      disabled
                    />
                  </div>
                  <div className="col-md-2 col-12">
                    <IconButton
                      color="secondary"
                      onClick={() => removeChennels(index)}
                    >
                      <span className="material-icons">delete</span>
                    </IconButton>
                  </div>
                </>
              ))}
            </div>
          </div>

          <div className="col-md-12 text-right">
            {userFromik.values.extra_chennel_temp_price > 0 ?
              < Zazorpay
                amount={
                  parseFloat(userFromik.values.extra_chennel_temp_price)
                }
                afterPayCall={() => {
                  userFromik.handleSubmit()
                  userFromik.setFieldValue("extra_chennel_temp_price", 0)
                }}
              />
              :
              <PrimaryButton
                label={user._id ? "Update / புதுப்பி" : "create / உருவாக்கு"}
                onClick={userFromik.handleSubmit}
              />}
          </div>
        </div>
      </div>
    </>
  );
};

export default EditUser;
