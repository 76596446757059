let channellogos = [
    {
        img: './images/suntv.png'
    },
    {
        img: './images/sunmusic.png'
    },
    {
        img: './images/colors.png',
    },
    {
        img: './images/zee.png',
    },
    {
        img: './images/vijay.png',
    },
    {
        img: './images/suryatv.png',
    },
    {
        img: './images/hbo.png',
    },
]

let bannerimages = [
    {
        img: './images/banner-new.svg',
    },
    // {
    //     img: './images/banner.png',
    // },
    // {
    //     img: './images/banner.png',
    // }
]

let loginimages = [
    {
        img: './images/log1.png',
    },
    {
        img: './images/log2.png',
    },
]

export { channellogos, bannerimages, loginimages };