import { gql } from "@apollo/client";

export const transactionPagination = gql`
  query transactionPagination(
    $page: Int
    $perPage: Int = 20
    $filter: FilterFindManyTransactionInput,
    $sort: SortFindManyTransactionInput
  ) {
    transactionPagination(page: $page, perPage: $perPage, filter: $filter,sort:$sort) {
      count
      pageInfo {
        currentPage
      }
      items {
        amount
        _id
        create_date
        process_status
        user_id
        User {
          fullname
        }
      }
    }
  }
`;

export const CreateOneTransactionInput = gql`
  mutation transactionCreateOne(
    $amount: Float!
    $user_id: MongoID!
    $currency: String
  ) {
    transactionCreateOne(
      amount: $amount
      user_id: $user_id
      currency: $currency
    ) {
      subscription_id
      amount
      ref_id
      process_status
    }
  }
`;

export const transactionUpdateOne = gql`
  mutation transactionUpdateOne(
    $amount: Float!
    $user_id: MongoID!
    $ref_id: String!
    $payment_id: String!
    $signature: String!
  ) {
    transactionUpdateOne(
      amount: $amount
      user_id: $user_id
      ref_id: $ref_id
      payment_id: $payment_id
      signature: $signature
    ) {
      subscription_id
      amount
      ref_id
      process_status
    }
  }
`;

export const transactionSingleCreateOne = gql`
  mutation transactionSingleCreateOne(
    $amount: Float!
    $user_id: MongoID!
    $currency: String
  ) {
    transactionSingleCreateOne(
      amount: $amount
      user_id: $user_id
      currency: $currency
    ) {
      subscription_id
      amount
      ref_id
      process_status
    }
  }
`;

export const transactionSingleUpdateOne = gql`
  mutation transactionSingleUpdateOne(
    $amount: Float!
    $user_id: MongoID!
    $ref_id: String!
    $payment_id: String!
    $signature: String!
  ) {
    transactionSingleUpdateOne(
      amount: $amount
      user_id: $user_id
      ref_id: $ref_id
      payment_id: $payment_id
      signature: $signature
    ) {
      subscription_id
      amount
      ref_id
      process_status
    }
  }
`;