import React from "react";
import BannerCarousel from "../../Components/BannerCarousel";
import MultiCarousel from "../../Components/MultiCarousel";
import { channellogos, bannerimages } from "../../Utils/Constants";
import ContactForm from "../../Components/ContactForm";
import Footer from "../../Components/Footer";

const Homepage = () => {
  return (
    <div className="viewPort hmPageContainer">
      <div className="bannerWrapper">
        <BannerCarousel images={bannerimages} />
      </div>
      <div className="chnlLogoCarousel mt-4">
        <h1 className="crlHd text-center mb-3 pl-3 pr-3">
          WATCH MULTI LANGUAGE CHANNELS ON A SINGLE CABLE
        </h1>
        <MultiCarousel images={channellogos} />
      </div>
      <div className="whyUsWrppr mt-5 container">
        <h2 className="ttleTxt text-center mb-5">
          How it<span className="ttleClr">WORKS?</span>
        </h2>
        <div className="contContainer row align-items-center mt-5">
          <div className="col-md-6 col-12">
            <h3 className="contHdr">Get Account details from your operator</h3>
            <p className="contDesc">
              {" "}
            </p>
          </div>
          <div className="col-md-6 col-12 text-center pl-3 pr-3">
            <img classNam='img-logo' src="./images/loginPage.svg" />
          </div>
        </div>
        <div className="contContainer row align-items-center mt-5">
          <div className="col-md-6 col-12 text-center pl-3 pr-3">
            <img src="./images/imgClickPay.svg" />
          </div>
          <div className="col-md-6 col-12">
            <h3 className="contHdr">Click to pay in one click</h3>
            <p className="contDesc">
              {" "}
            </p>
          </div>
        </div>
        <div className="contContainer row align-items-center mt-5">
          <div className="col-md-6 col-12 text-right">
            <h3 className="contHdr">Manage your billings in one page</h3>
            <p className="contDesc">
              {" "}
            </p>
          </div>
          <div className="col-md-6 col-12 text-center pl-3 pr-3">
            <img src="./images/imgBills.svg" />
          </div>
        </div>
      </div>
      <div id="contactus" className="contactWrapper whyUsWrppr mt-4">
        <h2 className="ttleTxt text-center mb-5">
          CONTACT<span className="ttleClr">US</span>
        </h2>
        <div className="row contactForm">
          <div className="col-lg-6 mt-5">
            <ContactForm />
          </div>
          <div className={"contact cntcFrmCls col-lg-6 mt-5"}>
            <div className="col-lg-12 info d-flex flex-row align-items-center" >
              <i className="bx bx-map"></i>
              <div>
                <h4>Address</h4>
                <p>No. 677, 1st Floor, 27th Main, 13th Cross, Sector 1, <br /> HSR Layout, Bangalore, Pin: 560102</p>
              </div>
            </div>
            <hr />
            <div className="col-lg-12 info d-flex flex-row align-items-center">
              <i className="bx bx-phone"></i>
              <div>
                <h4>Call Us</h4>
                <p>+91 9791879842</p>
              </div>
            </div>
            <hr />
            <div className="col-lg-12 info d-flex flex-row align-items-center">
              <i className="bx bx-envelope"></i>
              <div>
                <h4>Email Us</h4>
                <p>support@whiteair.in</p>
              </div>
            </div>
            <hr />
            <div className="col-lg-12 info d-flex flex-row align-items-center">
              <i className="bx bx-time-five"></i>
              <div>
                <h4>Support Hours</h4>
                <p>Mon - Fri: 9AM to 5PM,<br /> Sunday: 9AM to 1PM</p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default Homepage;
