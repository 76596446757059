import React, { useState, useEffect, useContext } from "react";
import Table from "../../Components/Table";

import { useQuery, useLazyQuery } from "@apollo/client";
import { transactionPagination } from "../../schema/transcation";

import { CommonContext } from "../../context/CommonContext";

function createData(col1, col2) {
  return { col1, col2 };
}

const PendingTable = () => {
  const { user, setAlert, setUser } = useContext(CommonContext);
  const [userInfo, setUserInfo] = useState({});
  const [rowValues, setRowValues] = useState([])
  const [variables, setVariables] = React.useState({
    filter: {
      user_id: "",
      process_status: "in_progress",
    },
    page: 1,
  });
  const [getPendingInfo, { called, error, data }] = useLazyQuery(
    transactionPagination,
    {
      variables: variables,
      skip: true,
    }
  );

  useEffect(() => {
    if (user) {
      if (user.isUser) {
        variables.filter = {
          user_id: "",
          process_status: "in_progress",
        };
        variables.filter.user_id = user._id;
        setVariables(variables);
        getPendingInfo(variables);
      } else {
        variables.filter = {
          operator_id: "",
          process_status: "in_progress",
        };
        variables.filter.operator_id = user._id
        setVariables(variables);
        getPendingInfo(variables);
      }
    }
  },[user]);

  useEffect(() => {
    if (error) {
      setAlert({ msg: "Try later!", success: 0 });
      // history.push('/userlogin')
    }
    if (!error && data) {
    let temp = []
      data.transactionPagination.items.map((data) => {
        temp.push(createData(data.create_date, data.amount))
      });
      setRowValues(temp)
    }
  }, [data, error]);

  const headerValues = [
    { id: "col1", numeric: false, disablePadding: false, label: "Date / தேதி" },
    {
      id: "col2",
      numeric: false,
      disablePadding: false,
      label: "Recharge Amount / ரீசார்ஜ் தொகை",
    },
  ];

  return <Table header={headerValues} rows={rowValues} />;
};

export default PendingTable;
