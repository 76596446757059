import React, { useRef } from 'react'
import { Link, NavLink, useHistory } from 'react-router-dom';
import PrimaryButton from '../Buttons/PrimaryButton';
import SecondaryButton from '../Buttons/SecondaryButton';
import './Header.css';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import clsx from "clsx";
import { makeStyles } from "@material-ui/core/styles";
import Drawer from "@material-ui/core/Drawer";
import Button from "@material-ui/core/Button";
import List from "@material-ui/core/List";
import Divider from "@material-ui/core/Divider";
import ListItem from "@material-ui/core/ListItem";
import { IconButton } from '@material-ui/core';

const useStyles = makeStyles({
    list: {
        width: 250
    },
    fullList: {
        width: "auto"
    }
});

const Header = () => {

    const history = useHistory();
    const mobile = useMediaQuery('(max-width:992px)')
    const classes = useStyles();
    const [state, setState] = React.useState({
        left: false
    });

    const toggleDrawer = (anchor, open) => (event) => {
        if (
            event.type === "keydown" &&
            (event.key === "Tab" || event.key === "Shift")
        ) {
            return;
        }

        setState({ ...state, [anchor]: open });
    };

    const list = (anchor) => (
        <div
            className={clsx(classes.list, {
                [classes.fullList]: anchor === "top" || anchor === "bottom"
            })}
            role="presentation"
        >
            <div className="sideBarHdr d-flex align-items-center justify-content-between">
                <h3 className="m-0">
                    <img className='img-logo' src="./images/AIrPayLogo.svg" />
                </h3>
                <IconButton onClick={toggleDrawer(anchor, false)}
                    onKeyDown={toggleDrawer(anchor, false)}>
                    <span className="material-icons">clear</span>
                </IconButton>
            </div>
            {/* <Divider />
            <List>
                <ListItem button onClick={toggleDrawer(anchor, false)}>
                    <NavLink to="/home" activeClassName="active">Home</NavLink>
                </ListItem>
            </List>
            <Divider />
            <List>
                <ListItem button onClick={toggleDrawer(anchor, false)}>
                    <NavLink to="/home#htw" isActive={() => {
                        return window.location.hash === '#htw';
                    }} activeClassName="inactive">How it's work</NavLink>
                </ListItem>
            </List>
            <Divider />
            <List>
                <ListItem button onClick={toggleDrawer(anchor, false)}>
                    <NavLink to="/home#contactus"
                        isActive={() => {
                            return window.location.hash === '#contactus';
                        }}
                        activeClassName="inactive"
                    >Contact Us</NavLink>
                </ListItem>
            </List>
            <Divider /> */}
            <List>
                <ListItem button onClick={toggleDrawer(anchor, false)}>
                    <NavLink to="/userlogin" activeClassName="inactive">Login</NavLink>
                </ListItem>
            </List>
            {/* <Divider />
            <List>
                <ListItem button onClick={toggleDrawer(anchor, false)}>
                    <NavLink to="/operatorlogin" activeClassName="inactive">Operator Login</NavLink>
                </ListItem>
            </List> */}
        </div>
    );

    return (
        <nav className="mainHeader d-flex align-items-center justify-content-between">
            <h2 className="m-0">
                <img className="img-logo" src="./images/AIrPayLogo.svg" />
            </h2>
            {!mobile ?
                <div className="rightNavs d-flex align-items-center">
                    {/* <NavLink to="/home" activeClassName="active">Home</NavLink>
                    <NavLink to="/home#htw" isActive={() => {
                        return window.location.hash === '#htw';
                    }} activeClassName="inactive">How it's work</NavLink>
                    <NavLink to="/home#contactus"
                        isActive={() => {
                            return window.location.hash === '#contactus';
                        }} activeClassName="inactive">Contact Us</NavLink> */}
                    <PrimaryButton className="" label="Login" onClick={() => { history.push('/userlogin') }} />
                    {/* <SecondaryButton className="" label="Operator Login" onClick={() => { history.push('/operatorlogin') }} /> */}
                </div>
                :
                <div>
                    {["right"].map((anchor) => (
                        <React.Fragment key={anchor}>
                            <Button variant="outlined" color="primary" onClick={toggleDrawer(anchor, true)}><span class="material-icons">
                                menu
                            </span></Button>
                            <Drawer
                                anchor={anchor}
                                open={state[anchor]}
                                onClose={toggleDrawer(anchor, false)}
                            >
                                <div className="sideBarWrapper">
                                    {list(anchor)}
                                </div>
                            </Drawer>
                        </React.Fragment>
                    ))}
                </div>}
        </nav>
    )
}

export default Header;