exports.setCookie = (name, value, days) => {
  if (days) {
    var date = new Date();
    date.setTime(date.getTime() + days * 24 * 60 * 60 * 1000);
  }

  var object = { value: value, timestamp: date.getTime() };
  localStorage.setItem(name, JSON.stringify(object));

  return null;
};

exports.getCookie = (name) => {
  var object = JSON.parse(localStorage.getItem(name));
  if (object && object.value  && object.value != "" && Object.keys(object.value).length) {
    var dateString = object.timestamp,
      now = new Date().getTime().toString();

    if (dateString > now) {
      return JSON.parse(object.value);
    } else {
      this.setCookie(name, "", 1);
    }
  }
  return null;
};
