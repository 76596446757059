import React from "react";
import Routes from "./Router/Routes";

import CommonContextProvider from "./context/CommonContext";
import { ToastContainer } from "react-toastify";

import "react-toastify/dist/ReactToastify.css";
import "./App.css";

function App() {
  return (
    <div className="App">
      <CommonContextProvider>
        <Routes />
      </CommonContextProvider>
      <ToastContainer />
    </div>
  );
}

export default App;
