import React, { useState, useEffect, useContext } from "react";
import { makeStyles } from '@material-ui/core/styles';
import TextInput from '../TextField'
import PrimaryButton from '../Buttons/PrimaryButton'
import { CommonContext } from "../../context/CommonContext";

import { useMutation } from "@apollo/client";
import { contactCreate } from "../../schema/contact";

import { useFormik } from "formik";
import * as Yup from "yup";

const useStyles = makeStyles((theme) => ({
    root: {
        '& > *': {
            margin: theme.spacing(1),
        },
    },
}));

export default function BasicTextFields() {
    const classes = useStyles();

    const { setAlert, setUser } = useContext(CommonContext);

    const initialValues = {
        mobile: "",
        name: "",
        email: "",
        message: "",
    };

    const [userData, setUserData] = useState(initialValues);
    const [createContact, { called, error, data }] = useMutation(contactCreate, {
        variables: { record: userData },
        skip: true,
    });

    const userValidationSchema = Yup.object({
        name: Yup.string().required("Name is required"),
        mobile: Yup.string().required("Mobile is required"),
        email: Yup.string().required("Email is required"),
    });

    const userFromik = useFormik({
        initialValues: initialValues,
        validationSchema: userValidationSchema,
        onSubmit: (values) => {
            setUserData({ ...values });
            createContact();
        },
    });

    useEffect(() => {
        if (error) setAlert({ msg: "Try Later", success: 0 });
        if (!error && data) {
            userFromik.resetForm(initialValues)
            userFromik.setSubmitting(false);
            setAlert({ msg: "Successfully sent!", success: 1 });
        }
    }, [data, error]);

    return (
        <form className={`${classes.root} cntcFrmCls`} autoComplete="off">
            {/* <TextField id="standard-basic" label="Standard" /> */}
            <TextInput required label="Name / பெயர்" name="name"
                value={userFromik.values.name}
                onChange={userFromik.handleChange}
                error={userFromik.errors} />
            <TextInput required label="Mobile No. / கைபேசி எண்" name="mobile"
                value={userFromik.values.mobile}
                onChange={userFromik.handleChange}
                error={userFromik.errors} />
            <TextInput required label="e-Mail / மின்னஞ்சல்" name="email"
                value={userFromik.values.email}
                onChange={userFromik.handleChange}
                error={userFromik.errors} />
            <TextInput required multiline={true} rows={6} className="msgFld" label="Message/ விவரம்" name="message"
                value={userFromik.values.message}
                onChange={userFromik.handleChange}
                error={userFromik.errors} />
            <PrimaryButton label="Submit / சமர்ப்பிக்கவும்" onClick={userFromik.handleSubmit} />
        </form>
    );
}
