import React, { useState, useEffect, useContext } from "react";
import { Link, useHistory } from "react-router-dom";
import ArrowBackIcon from "@material-ui/icons/ArrowBack";
import SecondaryButton from "../../Components/Buttons/SecondaryButton";
import Table from "../../Components/Table";

import { useQuery, useLazyQuery } from "@apollo/client";
import { userPagination } from "../../schema/user";
import { CommonContext } from "../../context/CommonContext";

function createData(col1, col2, col3, col4) {
  return { col1, col2, col3, col4 };
}

const UserDetails = () => {
  const history = useHistory();
  const { user, setAlert } = useContext(CommonContext);
  const [rowValues, setRowValues] = useState([]);
  const [variables, setVariables] = React.useState({
    filter: {
      isUser: true,
      operator_id: user._id,
    },
    page: 1,
  });
  const [getUserInfo, { called, error, data }] = useLazyQuery(userPagination, {
    variables: variables,
    skip: true,
  });

  useEffect(() => {
    if (user) {
      getUserInfo(variables);
    }
  }, [user]);

  useEffect(() => {
    if (error) {
      setAlert({ msg: "Try later!", success: 0 });
    }
    if (!error && data) {
      let temp = [];
      data?.userPagination?.items &&
        data.userPagination.items.map((data) => {
          temp.push(
            createData(
              data.fullname,
              data?.Subscription?.next_pay_amount || 0.0,
              data.address,
              data.phone
            )
          );
        });
      setRowValues(temp);
    }
  }, [data, error]);

  const headerValues = [
    {
      id: "col1",
      numeric: false,
      disablePadding: false,
      label: "User Name / பயனர் பெயர்",
    },
    {
      id: "col2",
      numeric: false,
      disablePadding: false,
      label: "Monthly Payment / மாதாந்திர கட்டணம்",
    },
    {
      id: "col3",
      numeric: false,
      disablePadding: false,
      label: "Address /  முகவரி",
    },
    {
      id: "col4",
      numeric: false,
      disablePadding: false,
      label: "Phone No. / கைபேசி எண்",
    },
  ];

  return (
    <div className="viewPort conctDetls">
      <div className="backButton text-left">
        <SecondaryButton
          onClick={() => history.push("/operatordashboard")}
          label={<ArrowBackIcon />}
        />
      </div>
      <div className="tableWrapper mt-4">
        <h5 className="mb-3">USER DETAILS</h5>
        <div className="tblFlwWrpr">
          {rowValues && <Table header={headerValues} rows={rowValues} />}
        </div>
      </div>
    </div>
  );
};

export default UserDetails;
