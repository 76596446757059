import React, { useState, createContext, useEffect } from "react";
import { setContext } from "@apollo/client/link/context";
import { getCookie } from "../Utils/cookies";

export const CommonContext = createContext();
export const CommonActionsContext = createContext();

const CommonContextProvider = (props) => {
  const [isLogin, setLogin] = useState(false);
  const [user, setUser] = useState(getCookie("__user2") || {});
  const [alert, setAlert] = useState({ msg: "", success: "" });

  useEffect(() => {
    if (getCookie("__user2") && getCookie("__user2") !='') {
      setUser((getCookie("__user2")));
    }
  }, []);

  return (
    <CommonContext.Provider
      value={{ isLogin, user, alert, setLogin, setUser, setAlert }}
    >
      {props.children}
    </CommonContext.Provider>
  );
};

export default CommonContextProvider;
