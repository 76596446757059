import React, { useEffect, useContext } from "react";
import { Switch, withRouter, Route, Redirect } from "react-router-dom";
import Header from "../Components/Header/Header";
import Homepage from "../Views/Homepage";
import UserLogin from "../Views/Login";
import UserDashboard from "../Views/Dashboard/UserDashboard";
import InnerHeader from "../Components/Header/InnerHeader";
import ConnectionDetails from "../Views/Dashboard/ConnectionDetails";
import TransactionDetails from "../Views/Dashboard/TransactionDetails";
import UserDetails from "../Views/Dashboard/CustomerDetails";
// import Profile from "../Views/Profile";
import Privacy from "../Views/Others/privacy";
import Terms from "../Views/Others/terms";
import Sla from "../Views/Others/sla"

import { toast } from "react-toastify";
import { CommonContext } from "../context/CommonContext";
import Profile from "../Views/Profile";

const Routes = (props) => {
  const { alert } = useContext(CommonContext);

  const setMsg1 = (msg) =>
    toast.success(msg, {
      position: "top-right",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
    });

  const setMsg2 = (msg) =>
    toast.error(msg, {
      position: "top-right",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
    });

  useEffect(() => {
    if (alert && alert.msg)
      if (alert.success) setMsg1(alert.msg);
      else setMsg2(alert.msg);
  }, [alert]);

  return (
    <>
      {props.location.pathname === "/home" && <Header />}
      {(props.location.pathname.includes("/userdashboard") && (
        <InnerHeader />
      )) ||
        (props.location.pathname.includes("/operatordashboard") && (
          <InnerHeader />
        )) ||
        (props.location.pathname.includes("/profile") && <InnerHeader />)}
      <Switch>
        <Route exact path="/">
          <Redirect to="/home" />
        </Route>
        <Route exact path="/home" component={Homepage} />
        <Route exact path="/terms" component={Terms} />
        <Route exact path="/sla" component={Sla} />
        <Route exact path="/privacy" component={Privacy} />
        <Route exact path="/userlogin" component={UserLogin} />
        <Route exact path="/operatorlogin" component={UserLogin} />
        <Route exact path="/userdashboard" component={UserDashboard} />
        <Route
          exact
          path="/userdashboard/connectiondetails"
          component={ConnectionDetails}
        />
        <Route
          exact
          path="/userdashboard/transactiondetails"
          component={TransactionDetails}
        />
        <Route exact path="/operatordashboard" component={UserDashboard} />
        <Route
          exact
          path="/operatordashboard/connectiondetails"
          component={ConnectionDetails}
        />
        <Route
          exact
          path="/operatordashboard/userdetails"
          component={UserDetails}
        />
        <Route
          exact
          path="/operatordashboard/transactiondetails"
          component={TransactionDetails}
        />
        <Route exact path="/profile" component={Profile} />
      </Switch>
    </>
  );
};

export default withRouter(Routes);
