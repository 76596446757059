import { gql } from "@apollo/client";

export const contactCreate = gql`mutation contactCreate($record: CreateOneContactInput!) {
    contactCreate(record: $record) {
      record {
        name
        mobile
        email
      }
    }
  }`;