import React, { useContext, useEffect, useState } from "react";
import { Link, useHistory } from "react-router-dom";
import ArrowBackIcon from "@material-ui/icons/ArrowBack";
import SecondaryButton from "../../Components/Buttons/SecondaryButton";
import SelectField from "../../Components/Select";
import LocationOnIcon from "@material-ui/icons/LocationOn";
import PhoneIcon from "@material-ui/icons/Phone";
import MailIcon from "@material-ui/icons/Mail";
import CustomModal from "../../Components/Modal";
import InfoIcon from "@material-ui/icons/Info";
import { InfoRounded } from "@material-ui/icons";
import PendingTable from "./PendingTable";

import { useQuery, useLazyQuery } from "@apollo/client";
import { userById } from "../../schema/user";
import {
  CreateOneTransactionInput,
  transactionUpdateOne,
} from "../../schema/transcation";

import { CommonContext } from "../../context/CommonContext";
import Zazorpay from "../../Components/Payment/Zazorpay";
import GooglePay from "../../Components/Payment/GooglePay";

const ConnectionDetails = (props) => {
  const history = useHistory();
  const { user, setAlert } = useContext(CommonContext);
  const [open, setOpen] = React.useState(false);
  const [user_id, setId] = React.useState({
    id: "",
  });
  const [userInfo, setUserInfo] = useState({});

  const [getUserInfo, { called, error, data }] = useLazyQuery(userById, {
    variables: user_id,
  });

  useEffect(() => {
    if (error) {
      setAlert({ msg: "Try later!", success: 0 });
      // history.push('/userlogin')
    }
    if (!error && data) {
      if (data.userById.isUser) {
        setUserInfo({ ...user, ...data.userById });
      }
    }
  }, [data, error]);

  useEffect(() => {
    if (user._id) {
      setId({ id: user._id });
      getUserInfo();
    }
  }, [user]);

  const handleModalClick = () => {
    setOpen(!open);
  };

  return (
    <>
      <div className="viewPort conctDetls">
        {/* <div className="backButton text-left">
          <SecondaryButton
            onClick={() => history.push("/userdashboard")}
            label={<ArrowBackIcon />}
          />
        </div> */}
        <div className="row">
          <div className="leftContainer col-5">
            {/* <SelectField
              label="Select your operator / ஆபரேட்டரைத் தேர்ந்தெடுக்கவும்"
              values={[1, 2, 3, 4]}
            /> */}
            <div className="oprtrCard d-flex align-items-center">
              {userInfo && userInfo?.Subscription?.Plan?.User && (
                <>
                  <img
                    className="oprLogo"
                    src={`${process.env.REACT_APP_BACKEND_URL.replace("/api/", '') +
                      "/uploads/logo/" +
                      userInfo.Subscription.Plan.User?.logo ||
                      "/images/oprlogo.png"
                      }`}
                    onError={({ currentTarget }) => {
                      currentTarget.onerror = null; // prevents looping
                      currentTarget.src = `/images/AIrPayLogo.svg`;
                    }}
                  />
                  <div className="oprDtls">
                    <h5 className="oprName">
                      {userInfo.Subscription.Plan.User.brand_name}
                    </h5>
                    <ul>
                      <li>
                        <span>
                          <LocationOnIcon />
                        </span>
                        {userInfo.Subscription.Plan.User.address}
                      </li>
                      <li>
                        <span>
                          {" "}
                          <PhoneIcon />{" "}
                        </span>
                        {userInfo.Subscription.Plan.User.phone}
                      </li>
                      <li>
                        <span>
                          {" "}
                          <MailIcon />{" "}
                        </span>
                        {userInfo.Subscription.Plan.User.email}
                      </li>
                    </ul>
                  </div>
                </>
              )}
            </div>
          </div>
          {userInfo?.Subscription && (
            <div className="rightContainer row col-7 pl-4">
              <div className="col-12 mb-5">
                <p className="amtTtle">
                  Monthly Payment (A) / மாதாந்திர கட்டணம்
                </p>
                <h4 className="amtDtl">
                  <span>₹</span>
                  {userInfo.Subscription.next_pay_amount || 0.0}
                </h4>
              </div>
              <div className="col-6 mb-5">
                <p className="amtTtle">
                  Last Month Payment / முந்தைய மாதம் செலுத்திய தொகை
                </p>
                <h4 className="amtDtl">
                  <span>₹</span>
                  {userInfo.Subscription.lat_pay_amount || 0.0}
                </h4>
              </div>
              <div className="col-6 mb-5">
                <div className="divWthMdl d-flex align-items-center">
                  <p className="amtTtle">
                    Pending Payment (B) /நிலுவையில் உள்ள தொகை
                  </p>
                  <SecondaryButton
                    onClick={handleModalClick}
                    label={<InfoRounded />}
                  />
                </div>
                <h4 className="amtDtl">
                  <span>₹</span>
                  {userInfo.Subscription.pending_pay_amount || 0.0}
                </h4>
              </div>
              <div className="col-6">
                <p className="amtTtle">
                  Total Amount Payable (A+B)/செலுத்த வேண்டிய தொகை
                </p>
                <h4 className="amtDtl">
                  <span>₹</span>
                  {userInfo.Subscription.pending_pay_amount +
                    userInfo.Subscription.next_pay_amount || 0.0}
                </h4>
              </div>
              <div className="col-6">
                {/*
                // Temporarily disable Zasorpay
                <div className="row clkPyBtn">
                  <div className="col">
                    <Zazorpay
                      amount={
                        parseFloat(userInfo.Subscription.pending_pay_amount +
                          userInfo.Subscription.next_pay_amount || 0.0) * 100
                      }
                      toShow={
                        parseFloat(userInfo.Subscription.pending_pay_amount +
                          userInfo.Subscription.next_pay_amount || 0.0)
                      }
                      afterPayCall={getUserInfo}
                    />
                  </div>
                </div>
                */}
                <div className="row">
                  <div className="col">
                    <GooglePay
                      amount={
                        parseFloat(userInfo.Subscription.pending_pay_amount +
                          userInfo.Subscription.next_pay_amount || 0.0) * 100
                      }
                      toShow={
                        parseFloat(userInfo.Subscription.pending_pay_amount +
                          userInfo.Subscription.next_pay_amount || 0.0)
                      }
                      afterPayCall={getUserInfo}
                    />
                  </div>
                </div>
              </div>
            </div>
          )}
        </div>
      </div>
      <CustomModal
        onClose={handleModalClick}
        open={open}
        title="Pending Payments"
      >
        <PendingTable />
      </CustomModal>
    </>
  );
};

export default ConnectionDetails;
