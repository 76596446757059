import { gql } from "@apollo/client";

export const listUser = gql`
  query userPagination(
    $filter: FilterFindManyUserInput
    $perPage: Int
    $page: Int
  ) {
    userPagination(filter: $filter, perPage: $perPage, page: $page) {
      count
      items {
        _id
        fullname
        phone
        email
        address
        isUser
        Subscription {
          next_pay_amount
          lat_pay_amount
          pending_pay_amount
          Plan {
            amount
            status
            User {
              address
            }
          }
        }
      }
      pageInfo {
        currentPage
        perPage
        pageCount
      }
    }
  }
`;

export const userById = gql`
  query userById($id: MongoID!) {
    userById(_id: $id) {
      token
      fullname
      phone
      email
      address
      isUser
      zipcode
      country_code
      currency
      password
      device_id
      operator_id
      fcm
      fb_id
      twitter_id
      logo
      brand_name
      status
      account
      _id
      extra_chennel {
        chennel
        value
      }
      extra_chennel_total
      Subscription {
        next_pay_amount
        lat_pay_amount
        pending_pay_amount
        Plan {
          amount
          status
          User {
            address
            brand_name
            logo
            phone
            email
          }
        }
      }
    }
  }
`;

export const userCount = gql`
  query userCount($filter: FilterUserInput) {
    userCount(filter: $filter)
  }
`;

export const register = gql`
  mutation register($record: CreateOneUserInput!) {
    register(record: $record) {
      record {
        email
        phone
        isUser
      }
    }
  }
`;

export const login = gql`
  query login(
    $mobile: String
    $password: String
    $fcm: String!
    $deviceid: String!
    $isUser: Boolean!
  ) {
    login(
      mobile: $mobile
      password: $password
      fcm: $fcm
      deviceid: $deviceid
      isUser: $isUser
    ) {
      _id
      token
      fullname
      phone
      email
      address
      isUser
      Subscription {
        next_pay_amount
        lat_pay_amount
        pending_pay_amount
        Plan {
          amount
          status
          User {
            address
            brand_name
            logo
            phone
            email
          }
        }
      }
    }
  }
`;

export const userUpdateOne = gql`
  mutation userUpdateOne(
    $record: UpdateOneUserInput!
    $filter: FilterUpdateOneUserInput
  ) {
    userUpdateOne(record: $record, filter: $filter) {
      record {
        _id
      }
    }
  }
`;

export const channelPagination = gql`
  query channelPagination(
      $page: Int
      $perPage: Int = 20
      $filter: FilterFindManyChannelInput
      $sort: SortFindManyChannelInput
    ) {
      channelPagination(
        page: $page
        perPage: $perPage
        filter: $filter
        sort: $sort
      ) {
        count
        pageInfo {
          pageCount
          perPage
          currentPage
        }
        items {
            _id
          name
          price
          operator_id
          status
        }
      }
  }`;

export const userPagination = gql`
  query userPagination(
    $page: Int
    $perPage: Int = 20
    $filter: FilterFindManyUserInput
  ) {
    userPagination(page: $page, perPage: $perPage, filter: $filter) {
      count
      pageInfo {
        currentPage
      }
      items {
        token
        fullname
        phone
        email
        address
        isUser
        Subscription {
          next_pay_amount
          lat_pay_amount
          pending_pay_amount
          Plan {
            amount
            status
            User {
              address
              brand_name
              logo
              phone
              email
            }
          }
        }
      }
    }
  }
`;
