import React, { useContext, useEffect, useState } from "react";
import './googlepay.css';

import { useMutation } from "@apollo/client";
import {
  CreateOneTransactionInput,
  transactionUpdateOne,
} from "../../schema/transcation";
import { CommonContext } from "../../context/CommonContext";

// Global key for canMakepayment cache.
const canMakePaymentCache = 'canMakePaymentCache';

const PayByGooglePay = (props) => {
  const { user, setAlert, setUser } = useContext(CommonContext);
  const [initPayload, setInitPayload] = useState(0);
  const [payload, setPayload] = useState({
    user_id: "",
    amount: "1",
    currency: "INR",
    ref_id: "",
    payment_id: "",
    signature: "",
  });
  const [paymentResponse, setPaymentResponse] = useState(null);
  const [orderId, setOrderId] = useState(0);

  // mutation to create transaction
  const [
    initPay,
    { called: initCalled, error: initError, data: initData },
  ] = useMutation(CreateOneTransactionInput, { variables: initPayload });

  // mutation to update transaction
  const [
    confirmPay,
    { called, error, data },
  ] = useMutation(transactionUpdateOne, { variables: payload });

  // check if payments can be made on this browser and set an initial payload
  const openPayModal = () => {
    if (!window.PaymentRequest) {
      console.log('Web payments are not supported in this browser.');
      return;
    }

    setInitPayload({
      user_id: user._id,
      amount: user.Subscription.next_pay_amount,
      currency: "INR",
    });
  };

  // send initial payload to create transaction
  useEffect(() => {
    if (initPayload) initPay();
  }, [initPayload, initPay]);

  // initialize Google Pay payment
  useEffect(() => {
    if (initError) {
      setAlert({ msg: "Try later!", success: 0 });
    }
    if (!initError && initData) {
      const paymentRequest = createPaymentRequest(initData.transactionCreateOne);

      var canMakePaymentPromise = checkCanMakePayment(paymentRequest);
      canMakePaymentPromise
        .then((result) => {
          setPaymentResponse(showPaymentUI(paymentRequest, result, setAlert));
        })
        .catch((err) => {
          console.log('Error calling checkCanMakePayment: ' + err);
        });
    }
  }, [initError, initData, setAlert]);

  // handle payment response
  useEffect(() => {
    if (paymentResponse) {
      processResponse(paymentResponse, user, setPayload);
    }
    if (payload && paymentResponse) {
      // send payment confirmation
      confirmPay(payload);
      paymentResponse.complete("success");
      setPaymentResponse(null);
    }
  }, [paymentResponse, user, setPayload, confirmPay, payload]);

  // handle payment success or failure
  useEffect(() => {
    if (error) {
      setAlert({ msg: "Payment not success", success: 0 });
    }
    if (!error && data) {
      setAlert({ msg: "Payment success", success: 1 });
      props.afterPayCall()
    }
  }, [data, error, props, setAlert]);

  return (
    <button
      className="googlepay"
      title="Google Pay"
      style={{ width: '100%' }}
      onClick={openPayModal}
      disabled={props.amount <= 0}
    />
  );
};

/**
 * Check whether can make payment with Google Pay or not. It will check session storage
 * cache first and use the cache directly if it exists. Otherwise, it will call
 * canMakePayment method from PaymentRequest object and return the result, the
 * result will also be stored in the session storage cache for future usage.
 *
 * @private
 * @param {PaymentRequest} request The payment request object.
 * @return {Promise} a promise containing the result of whether can make payment.
 */
function checkCanMakePayment(request) {
  // Check canMakePayment cache, use cache result directly if it exists.
  if (sessionStorage.hasOwnProperty(canMakePaymentCache)) {
    console.log('Can make Google Pay payment via 11 https://tez.google.com/pay: ' + sessionStorage[canMakePaymentCache]);
    return Promise.resolve(JSON.parse(sessionStorage[canMakePaymentCache]));
  }

  // If canMakePayment() isn't available, default to assume the method is
  // supported.
  var canMakePaymentPromise = Promise.resolve(true);

  // Feature detect canMakePayment().
  if (request.canMakePayment) {
    canMakePaymentPromise = request.canMakePayment();
  }

  return canMakePaymentPromise
    .then((result) => {
      console.log('Can make Google Pay payment via https://tez.google.com/pay: ' + result);
      // Store the result in cache for future usage.
      sessionStorage[canMakePaymentCache] = result;
      return result;
    })
    .catch((err) => {
      console.log('Error calling canMakePayment: ' + err);
    });
}

/**
* Show the payment request UI.
*
* @private
* @param {PaymentRequest} request The payment request object.
* @param {Promise} canMakePayment The promise for whether can make payment.
* @param {function} setAlert The function to set alert message.
*/
function showPaymentUI(request, canMakePayment, setAlert) {
  console.log(request)
  if (!canMakePayment) {
    handleNotReadyToPay(setAlert);
    return;
  }

  // Set payment timeout.
  let paymentTimeout = window.setTimeout(function () {
    window.clearTimeout(paymentTimeout);
    request.abort()
      .then(function () {
        console.log('Payment timed out after 20 minutes.');
        return null;
      })
      .catch(function () {
        console.log('Unable to abort, user is in the process of paying.');
        return null;
      });
  }, 20 * 60 * 1000); /* 20 minutes */

  request.show()
    .then(function (response) {

      window.clearTimeout(paymentTimeout);
      return response;
    })
    .catch(function (err) {
      console.log(err);
      return null;
    });
}

/**
 * Define your unique Google Pay API configuration
 * @param {object} transactionData transaction data
 * @returns {object} data attribute suitable for PaymentMethodData
 */
function getGooglePaymentsConfiguration(transactionData) {
  return [
    {
      supportedMethods: ['https://google.com/pay'],
      data: {
        pa: 'whiteair@icici',
        pn: 'OnePaise Cable Partner',
        tr: transactionData.ref_id,  // your custom transaction reference ID
        url: 'https://onepaise.whiteair.in/home',
        mc: '4814', // your merchant category code
        tn: 'Your cableTV bill has been recharged',
        cu: 'INR', // currency code
        am: transactionData.amount, // amount
      },
    }
  ];
}

/**
 * Create a PaymentRequest
 * @param {object} transactionData transaction data
 * @returns {PaymentRequest}
 */
function createPaymentRequest(transactionData) {
  const details = {
    total: {
      label: 'Total',
      amount: {
        currency: 'INR',
        value: transactionData.amount.toString(),
      },
    },
    displayItems: [{
      label: 'Original Amount',
      amount: {
        currency: 'INR',
        value: transactionData.amount.toString(),
      },
    }],
  };

  let request = null;
  try {
    request = new PaymentRequest(getGooglePaymentsConfiguration(transactionData), details);
  } catch (e) {
    console.log('Payment Request Error: ' + e.message);
    return;
  }
  if (!request) {
    console.log('Web payments are not supported in this browser.');
    return;
  }
  return request;
}

/** Handle Google Pay not ready to pay case. */
function handleNotReadyToPay(setAlert) {
  setAlert({ msg: "Google Pay is not ready to pay.", success: 0 });
}

/**
* Process the response from browser.
*
* @private
* @param {PaymentResponse} instrument The payment instrument that was authed.
*/
function processResponse(paymentResponse, user, setPayload) {
  // Handle successful payment
  setPayload({
    user_id: user._id,
    amount: user.Subscription.next_pay_amount,
    currency: "INR",
    ref_id: paymentResponse.tezResponse.txnRef,
    payment_id: paymentResponse.tezResponse.txnId,
    signature: "true",
  });
}

export default PayByGooglePay;