import React, { useContext, useEffect, useState } from "react";
import PrimaryButton from "../Buttons/PrimaryButton";

import { useMutation } from "@apollo/client";
import {
  transactionSingleCreateOne,
  transactionSingleUpdateOne,
} from "../../schema/transcation";
import { CommonContext } from "../../context/CommonContext";

const PayByRazorPay = (props) => {
  const { user, setAlert, setUser } = useContext(CommonContext);
  const [initPayload, setInitPayload] = useState(0);
  const [payload, setPayload] = useState({
    user_id: "",
    amount: "",
    currency: "INR",
    ref_id: "",
    payment_id: "",
    signature: "",
  });
  const [orderId, setOrderId] = useState(0);
  let rzp1;

  const [
    initPay,
    { called: initCalled, error: initError, data: initData },
  ] = useMutation(transactionSingleCreateOne, { variables: initPayload });

  const [
    confirmPay,
    { called, error, data },
  ] = useMutation(transactionSingleUpdateOne, { variables: payload });

  useEffect(() => {
    if (initError) {
      setAlert({ msg: "Try later!", success: 0 });
    }
    if (!initError && initData) {
      console.log(initData);
      const options = {
        key: process.env.REACT_APP_RAZORPAY_KEY,
        amount: props.amount, //  = INR 1
        name: "onepaise",
        description: "Payment for cable tv",
        order_id: initData.transactionSingleCreateOne.ref_id,
        image: "https://cdn.razorpay.com/logos/7K3b6d18wHwKzL_medium.png",
        handler: function (response) {
          console.log(response)
          setPayload({
            user_id: user._id,
            amount: props.amount,
            currency: "INR",
            ref_id: response.razorpay_order_id,
            payment_id: response.razorpay_payment_id,
            signature: "true",
          });
          confirmPay(payload);
        },
        prefill: {
          name: user.fullname,
          email: user.email,
          contact: user.phone,
        },
        theme: {
          color: "black",
          hide_topbar: false,
        },
        method: {
          netbanking: false,
          card: true,
          upi: true,
          wallet: false
        }
      };
      rzp1 = new window.Razorpay(options);
      rzp1.open();
    }
  }, [initData, initError]);

  useEffect(() => {
    if (error) {
      setAlert({ msg: "Payment not success", success: 0 });
      // history.push('/userlogin')
    }
    if (!error && data) {
      setAlert({ msg: "Payment success", success: 1 });
      props.afterPayCall()
    }
  }, [data, error]);

  useEffect(() => {
    if (initPayload) initPay();
  }, [initPayload]);

  const openPayModal = () => {
    setInitPayload({
      user_id: user._id,
      amount: props.amount,
      currency: "INR",
    });
  };

  useEffect(() => {
    const script = document.createElement("script");
    script.src = "https://checkout.razorpay.com/v1/checkout.js";
    script.async = true;
    document.body.appendChild(script);
    return () => {
      setInitPayload(0);
    };
  }, []);

  return (
    <div className="clkPyBtnLg">
      <PrimaryButton
        label={
          <p className="m-0">
            Click to Pay <br /> கட்டணம் செலுத்த இங்கே கிளிக் செய்க {props.amount}
          </p>
        }
        onClick={openPayModal}
        disabled={props.amount <= 0}
      />
    </div>
  );
};

export default PayByRazorPay;
