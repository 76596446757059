import React, { useState, useEffect, useContext } from "react";
import { Link, useHistory } from "react-router-dom";
import ArrowBackIcon from "@material-ui/icons/ArrowBack";
import SecondaryButton from "../../Components/Buttons/SecondaryButton";
import Table from "../../Components/Table";

import { useQuery, useLazyQuery } from "@apollo/client";
import { transactionPagination } from "../../schema/transcation";
import { CommonContext } from "../../context/CommonContext";

function createData(col1, col2, col3, col4, col5) {
  return { col1, col2, col3, col4, col5 };
}

const TransactionDetails = (props) => {
  const history = useHistory();
  const { user, setAlert } = useContext(CommonContext);
  const [rowValues, setRowValues] = useState([]);
  const [variables, setVariables] = React.useState({
    filter: {
      user_id: "",
      process_status: "success",
    },
    sort: "_ID_DESC",
    page: 1,
  });
  const [getPendingInfo, { called, error, data }] = useLazyQuery(
    transactionPagination,
    {
      variables: variables,
      skip: true,
    }
  );

  useEffect(() => {
    if (user) {
      if (user.isUser) {
        variables.filter = {
          user_id: "",
          process_status: "success",
        };
        variables.filter.user_id = user._id;
        setVariables(variables);
        getPendingInfo(variables);
      } else {
        variables.filter = {
          operator_id: "",
        };
        variables.filter.operator_id = user._id;
        setVariables(variables);
        getPendingInfo(variables);
      }
    }
  }, [user]);

  useEffect(() => {
    if (error) {
      setAlert({ msg: "Try later!", success: 0 });
      // history.push('/userlogin')
    }
    if (!error && data) {
      let temp = [];
      data.transactionPagination.items.map((data) => {
        if (user.isUser)
          temp.push(
            createData(
              data.create_date,
              data.amount,
              data._id,
              data.process_status
            )
          );
        else
          temp.push(
            createData(
              data.create_date,
              data?.User?.fullname || "",
              data.amount,
              data._id,
              data.process_status
            )
          );
      });
      setRowValues(temp);
    }
  }, [data, error]);

  const headerValues = [
    { id: "col1", numeric: false, disablePadding: false, label: "Date / தேதி" },
    {
      id: "col3",
      numeric: false,
      disablePadding: false,
      label: "Recharge Amount / ரீசார்ஜ் தொகை",
    },
    {
      id: "col4",
      numeric: false,
      disablePadding: false,
      label: "Transaction Id /  பரிவர்த்தனை ஐடி",
    },
    {
      id: "col5",
      numeric: false,
      disablePadding: false,
      label: "Payment Status / கட்டண நிலை",
    },
  ];

  const OprtrheaderValues = [
    { id: "col1", numeric: false, disablePadding: false, label: "Date / தேதி" },
    {
      id: "col2",
      numeric: false,
      disablePadding: false,
      label: "User Name / பயனர் பெயர்",
    },
    {
      id: "col3",
      numeric: false,
      disablePadding: false,
      label: "Recharge Amount / ரீசார்ஜ் தொகை",
    },
    {
      id: "col4",
      numeric: false,
      disablePadding: false,
      label: "Transaction Id /  பரிவர்த்தனை ஐடி",
    },
    {
      id: "col5",
      numeric: false,
      disablePadding: false,
      label: "Payment Status / கட்டண நிலை",
    },
  ];

  return (
    <div className="viewPort conctDetls">
      <div className="backButton text-left">
        {props.location.pathname.includes("/userdashboard") ? (
          <SecondaryButton
            onClick={() => history.push("/userdashboard")}
            label={<ArrowBackIcon />}
          />
        ) : (
          <SecondaryButton
            onClick={() => history.push("/operatordashboard")}
            label={<ArrowBackIcon />}
          />
        )}
      </div>
      <div className="tableWrapper mt-4">
        <h5 className="mb-3">TRANSACTIONS</h5>
        <div className="tblFlwWrpr">
          {props.location.pathname.includes("/userdashboard") && rowValues && (
            <Table header={headerValues} rows={rowValues} />
          )}
          {props.location.pathname.includes("/operatordashboard") &&
            rowValues && <Table header={OprtrheaderValues} rows={rowValues} />}
        </div>
      </div>
    </div>
  );
};

export default TransactionDetails;
